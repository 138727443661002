import { useCallback } from 'react';
import { Layout, Table, PageHeader, Button, Input, AutoComplete } from 'antd';
import { css } from '@emotion/css';
import { useHistory } from 'react-router';

const dataSource = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
  },
];

const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
];

export default function TournamentList() {
  const history = useHistory();

  const onCreateNewTournament = useCallback(
    function onCreateNewTournamentCallback() {
      history.push({ pathname: '/app/createTournament' });
    },
    [history]
  );

  return (
    <Layout
      hasSider
      className={css`
        padding: 0 20px;
        background: transparent;
      `}
    >
      <Layout.Sider
        theme="light"
        className={css`
          margin-right: 20px;
        `}
      >
        Filters
      </Layout.Sider>
      <Layout>
        <PageHeader
          ghost={false}
          title="Tournaments"
          extra={
            <div
              className={css`
                display: flex;
              `}
            >
              <AutoComplete
                dropdownMatchSelectWidth={252}
                style={{ width: 300, marginRight: 20 }}
                options={[{ label: 'sds', value: 'asdasdasd' }]}
                onSelect={console.log}
                onSearch={console.log}
              >
                <Input.Search
                  placeholder="Search tournament here..."
                  onSearch={console.log}
                  enterButton
                />
              </AutoComplete>
              <Button key="1" type="primary" onClick={onCreateNewTournament}>
                Create Your own Tournament
              </Button>
            </div>
          }
        />
        <Table dataSource={dataSource} columns={COLUMNS} />
      </Layout>
    </Layout>
  );
}
