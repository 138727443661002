import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import HomePage from '../pages/Home/Home';
import FlexContainer from '../components/FlexContainer/FlexContainer';
import GuestNavigator from '../components/GuestNavigator/GuestNavigator';
import { AuthContext } from '../contexts/AuthContext';

export default function GuestRouter() {
  const { isAuth } = useContext(AuthContext);

  return (
    <Route path="/home">
      <GuestNavigator />
      <FlexContainer>
        <Switch>
          <Route path="/home" exact>
            {isAuth ? (
              <Redirect to="/app" from="/" exact strict />
            ) : (
              <HomePage />
            )}
          </Route>
          <Route path="/about">about</Route>
        </Switch>
      </FlexContainer>
    </Route>
  );
}
