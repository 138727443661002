import { css } from '@emotion/css';
import { Layout } from 'antd';

export default function NewTournament() {
  return (
    <Layout
      className={css`
        padding: 20px;
        margin: 0 20px;
      `}
    >
      asdasdasdasdsadasdasdasdasdas
    </Layout>
  );
}
