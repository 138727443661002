import { useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { css } from '@emotion/css';
import ChatMenu from '../ChatMenu/ChatMenu';
import AddChatMenu from '../AddChatMenu/AddChatMenu';

export default function ChatList() {
  const { state, search } = useLocation();
  const history = useHistory();

  const handleChatPress = useCallback(
    function handleChatPressCallback(key) {
      history.push({ pathname: `/app/chat/${key}` });
    },
    [history]
  );

  const [chats, setChats] = useState<Array<any>>([
    {
      name: 'server',
      key: 'server',
      type: 'server',
    },
    {
      name: 'BanchoBot',
      key: 'BanchoBot',
      type: 'private',
    },
    {
      name: 'NC: Ryovann vs. Chaphe',
      key: '#mp_94833148',
      type: 'channel',
      matchId: '94833148',
    },
  ]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        className={css`
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 10px;
          padding-bottom: 10px;
        `}
      >
        <Col
          span={20}
          className={css`
            display: flex;
            align-items: center;
          `}
        >
          Chats
        </Col>
        <Col
          span={4}
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          `}
        >
          <AddChatMenu triggers={['click']} />
        </Col>
      </Row>
      {chats.map(function mapChatsToComponents({ name, key, type, matchId }) {
        return (
          <Row gutter={[16, 16]}>
            <Col
              onClick={function handleChatNameColumnClick() {
                return handleChatPress(key);
              }}
              span={20}
              className={css`
                cursor: pointer;
                min-height: 45px;
                word-wrap: wrap;
                overflow: visible;
                padding: 10px;

                &:hover {
                  background: hsla(0, 0%, 80%, 1);

                  background: linear-gradient(
                    45deg,
                    hsla(0, 0%, 80%, 0.5) 0%,
                    hsla(0, 0%, 93%, 0.5) 100%
                  );

                  background: -moz-linear-gradient(
                    45deg,
                    hsla(0, 0%, 80%, 0.5) 0%,
                    hsla(0, 0%, 93%, 0.5) 100%
                  );

                  background: -webkit-linear-gradient(
                    45deg,
                    hsla(0, 0%, 80%, 0.5) 0%,
                    hsla(0, 0%, 93%, 0.5) 100%
                  );

                  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#CCCCCC", endColorstr="#EEEEEE", GradientType=1 );
                }
              `}
            >
              {name}
            </Col>
            <Col
              span={4}
              className={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              `}
            >
              <ChatMenu
                triggers={['click']}
                key={key}
                matchId={matchId}
                name={name}
                type={type}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
}
