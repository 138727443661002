import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import watchWebVitals from './utils/measurePerformance';
import 'antd/dist/antd.css';
import 'react-virtualized/styles.css';

// https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/#jwt_persist

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

watchWebVitals();
