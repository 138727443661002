import {
  createContext,
  Context,
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  BanchoConnection,
  BanchoEvents,
  BanchoMessages,
} from '../models/banchoConnectionType';
import { io, Socket } from 'socket.io-client';

export const BanchoCOnnectionContext: Context<Partial<BanchoConnection>> =
  createContext({});

export const BanchoConnectionProvider: FC = function BanchoConnectionProvider({
  children,
}) {
  const [banchoStatus, setBanchoStatus] = useState<
    'connected' | 'disconnected' | 'connecting' | 'reconnecting' | 'ready'
  >('disconnected');

  const [socket, setSocket] = useState<Socket>();

  useEffect(function mountEffect() {
    const socketInstance = io('ws://localhost:8080/bancho', {
      path: '/websockets',
      transports: ['websocket'],
    });

    socketInstance.on('connect', () => {
      setBanchoStatus('ready');
    });

    socketInstance.on('bancho:connected', () => {
      setBanchoStatus('connected');
    });

    socketInstance.on('disconnect', () => {
      setBanchoStatus('disconnected');
    });

    socketInstance.io.on('reconnect', () => {
      setBanchoStatus('reconnecting');
    });

    socketInstance.connect();

    setSocket(socketInstance);
  }, []);

  const connect = useCallback(
    function connectCallback() {
      if (!socket) {
        return;
      }

      socket.emit('bancho:connect');
    },
    [socket]
  );

  const disconnect = useCallback(
    function disconnectCallback() {
      if (!socket) {
        return;
      }

      socket.emit('bancho:disconnect');
    },
    [socket]
  );

  const [messages, setMessages] = useState<BanchoMessages>({});
  const [events, setEvents] = useState<BanchoEvents>();

  const handleMessage = useCallback(function handleMessageCallback(message) {},
  []);

  const sendMessage = useCallback(
    function sendMessageCallback(target: string, message: string) {
      return true;
    },
    [socket]
  );

  const sendEvent = useCallback(
    function sendMessageCallback(event: string, data: any) {
      return true;
    },
    [socket]
  );

  return (
    <BanchoCOnnectionContext.Provider
      value={{
        connect,
        disconnect,
        sendEvent,
        sendMessage,
        status: banchoStatus,
      }}
    >
      {children}
    </BanchoCOnnectionContext.Provider>
  );
};
