import { getCLS, getFID, getFCP, getLCP, getTTFB, ReportHandler } from 'web-vitals';

const logPerformance: ReportHandler = function logPerformance (metric) {
  console.log(metric);
  // post events to amplitude in the future
}

export default function startPerformanceMeasurement () {
  getCLS(logPerformance);
  getFID(logPerformance);
  getFCP(logPerformance);
  getLCP(logPerformance);
  getTTFB(logPerformance);
};
