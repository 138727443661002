import { css } from '@emotion/css';
import { Layout } from 'antd';

export default function AuthenticatedFooter() {
  return (
    <Layout.Footer
      style={{ textAlign: 'center' }}
      className={css`
        margin-top: 20px;
      `}
    ></Layout.Footer>
  );
}
