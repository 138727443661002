import { useContext } from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import DashboardPage from '../pages/Dashboard/Dashboard';
import IrcClient from '../pages/IrcClient/IrcClient';
import AuthenticatedNavigator from '../components/AuthenticatedNavigator/AuthenticatedNavigator';
import AuthenticatedFooter from '../components/AuthenticatedFooter/AuthenticatedFooter';
import TournamentsPage from '../pages/Tournaments/Tournaments';
import NewTournament from '../pages/NewTorunament/NewTournament';
import { Layout } from 'antd';
import { css } from '@emotion/css';
import Chat from '../pages/Chat/Chat';
import ChatList from '../components/ChatList/ChatList';

export default function ChatRouter() {
  const { path } = useRouteMatch();

  return (
    <Layout hasSider>
      <Layout.Content>
        <Switch>
          <Route path={path} exact strict={false}>

          </Route>
          <Route path={`${path}/:roomId`} component={Chat} />
        </Switch>
      </Layout.Content>
      <Layout.Sider
        theme="light"
        width={300}
        className={css`
          padding: 20px;
          border: 1px solid rgba(0, 0, 0, 0.2);
        `}
      >
        <ChatList />
      </Layout.Sider>
    </Layout>
  );
}
