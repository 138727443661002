import { useCallback } from 'react';
import { Layout, Menu } from 'antd';
import { css } from '@emotion/css';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import UserAvatarMenu from '../UserAvatarMenu/UserAvatarMenu';
import { colors } from '../../utils/colors';

export default function AuthenticatedNavigator() {
  const history = useHistory();
  const location = useLocation();

  const markLinkAsActive = useCallback(function markActiveLinkCallback(
    linkUrl
  ) {
    return function checkLinkActiveness(_, currentLocation) {
      return currentLocation.pathname === linkUrl;
    };
  },
  []);

  return (
    <Layout.Header
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px !important;
        margin-bottom: 20px;
        border-bottom: 1px solid #f0f0f0;
        background: #fff;
        box-shadow: 0px 10px 22px 5px #235789;
      `}
    >
      <Menu
        theme="light"
        mode="horizontal"
        style={{ width: '100%' }}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/app/home">
          <NavLink to="/app/home">Home</NavLink>
        </Menu.Item>
        <Menu.Item key="/app/tournaments">
          <NavLink
            to="/app/tournaments"
            isActive={markLinkAsActive('/app/tournaments')}
          >
            Tournaments
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/app/irc">
          <NavLink to="/app/irc" isActive={markLinkAsActive('/app/irc')}>
            Osu! chat
          </NavLink>
        </Menu.Item>
      </Menu>
      <UserAvatarMenu />
    </Layout.Header>
  );
}
