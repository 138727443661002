import { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import AuthType from '../../models/authType';

export default function AuthCallbackPage() {
  const { setToken } = useContext(AuthContext) as AuthType;
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(
    function memorizeSearchParams() {
      return new URLSearchParams(location.search);
    },
    [location.search]
  );

  useEffect(
    function searchParamEffect() {
      if (searchParams) {
        const token = searchParams.get('token');

        if (token) {
          setTimeout(function setTokenTimeout() {
            setToken(token);
            history.replace({ pathname: '/app' });
          }, 5000);
        }
      }
    },
    [searchParams, history, setToken]
  );

  return (
    <div>Logging you in.... You will be redirected in a couple of seconds</div>
  );
}
