import { Layout, Menu } from 'antd';
import { css } from '@emotion/css';
import { NavLink, useLocation } from 'react-router-dom';

export default function () {
  const location = useLocation();

  return (
    <Layout.Header
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px !important;
      `}
    >
      <Menu
        theme="dark"
        mode="horizontal"
        style={{ width: '100%' }}
        activeKey={location.pathname}
      >
        <a href="http://localhost:8080/oauth/osu">Login</a>
      </Menu>
    </Layout.Header>
  );
}
