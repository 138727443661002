import { gql } from '@apollo/client';

export const LogoutMutation = gql`
  mutation LogoutMutation {
    logout
  }
`;

export const UserQuery = gql`
  query UserAvatarMenuQuery {
    me {
      id
      externalId
      avatar
      username
      countryCode
      countryName
      cover
      osuJoinDate
      defaultMode
      osuActive
      osuLastVisit
      restricted
      discord
    }
  }
`;
