import {
  createContext,
  useEffect,
  useState,
  useMemo,
  Context,
  FC,
} from 'react';
import AuthType from '../models/authType';

const AUTH_STORAGE_KEY = 'storage:auth';

export const AuthContext: Context<Partial<AuthType>> = createContext({});

export const AuthProvider: FC = function AuthProvider({ children }) {
  const [token, setToken] = useState(function calculateState() {
    try {
      const value = window.localStorage.getItem(AUTH_STORAGE_KEY);
      return value ? JSON.parse(value) : '';
    } catch (e) {
      return '';
    }
  });

  useEffect(
    function AuthProviderTokenChangeEffect() {
      try {
        window.localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(token));
      } catch (e) {
        console.error(e);
      }
    },
    [token]
  );

  const isAuth = useMemo(
    function isAuthMemorization() {
      return !!token && token !== '' && token !== 0 && token !== null;
    },
    [token]
  );

  return (
    <AuthContext.Provider value={{ token, setToken, isAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
