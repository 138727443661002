import { useParams } from 'react-router-dom';

type ChatParams = {
  roomId: string;
};

export default function Chat() {
  const { roomId } = useParams<ChatParams>();

  return <div>{roomId}</div>;
}
