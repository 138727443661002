import { useContext } from 'react';
import { Route, Redirect, RouteProps, Switch } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import DashboardPage from '../pages/Dashboard/Dashboard';
import IrcClient from '../pages/IrcClient/IrcClient';
import AuthenticatedNavigator from '../components/AuthenticatedNavigator/AuthenticatedNavigator';
import AuthenticatedFooter from '../components/AuthenticatedFooter/AuthenticatedFooter';
import TournamentsPage from '../pages/Tournaments/Tournaments';
import NewTournament from '../pages/NewTorunament/NewTournament';
import { Layout } from 'antd';
import { css } from '@emotion/css';
import ChatRouter from './ChatRouter';
import { BanchoConnectionProvider } from '../contexts/banchoConnection';

export default function AuthenticatedRouter() {
  return (
    <PrivateRoute path="/app">
      <BanchoConnectionProvider>
        <AuthenticatedNavigator />
        <Layout.Content
          className={css`
            background: #fff;
            margin: 0 20px;
            padding: 20px;
          `}
        >
          <Switch>
            <Route path="/" strict exact>
              <DashboardPage />
            </Route>
            <Route path="/app/tournaments/">
              <TournamentsPage />
            </Route>
            <Route path="/app/createTournament">
              <NewTournament />
            </Route>
            <Route path="/app/chat">
              <ChatRouter />
            </Route>
          </Switch>
        </Layout.Content>
        <AuthenticatedFooter />
      </BanchoConnectionProvider>
    </PrivateRoute>
  );
}

const PrivateRoute = function PrivateRoute({
  children,
  ...otherProps
}: RouteProps) {
  const { isAuth } = useContext(AuthContext);

  return (
    <Route
      {...otherProps}
      render={function renderPrivateRouteComponent({ location }) {
        return isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
