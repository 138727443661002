import Router from './router';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { useContext } from 'react';

function ApolloApp() {
  const { token } = useContext(AuthContext);

  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : undefined;

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
    cache: new InMemoryCache(),
    headers,
  });

  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <ApolloApp />
    </AuthProvider>
  );
}
