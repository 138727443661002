import { useMemo } from 'react';
import { Menu, Dropdown } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

interface ChatMenuProps {
  triggers: Array<'click' | 'contextMenu' | 'hover'>;
}

export default function AddChatMenu({ triggers = ['click'] }: ChatMenuProps) {
  const chatMenuOverlay = useMemo(function memorizeChatMenuOverlay() {
    return (
      <Menu>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.antgroup.com"
          >
            1st menu item
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.aliyun.com"
          >
            2nd menu item
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.luohanacademy.com"
          >
            3rd menu item
          </a>
        </Menu.Item>
      </Menu>
    );
  }, []);

  return (
    <Dropdown
      trigger={triggers}
      placement="bottomRight"
      arrow
      overlay={chatMenuOverlay}
      className={css`
        border-radius: 50%;
        background: #eee;
        padding: 6px;

        &:hover {
          background: #ccc;
        }
      `}
    >
      <PlusOutlined style={{ fontSize: '20px' }} />
    </Dropdown>
  );
}
