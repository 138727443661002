import { useCallback, useContext, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, notification, Menu, Dropdown } from 'antd';
import { css } from '@emotion/css';
import {
  SettingOutlined,
  ProfileOutlined,
  LogoutOutlined,
  LinkOutlined,
} from '@ant-design/icons';

import {
  UserQueryType,
  UserQueryVariablesType,
} from '../../models/graphql/user';
import { QueryType } from '../../models/graphql/graphql';
import UserType from '../../models/user';
import { AuthContext } from '../../contexts/AuthContext';
import AuthType from '../../models/authType';
import { LogoutMutation, UserQuery } from './UserAvatarMenueGraphDefinitions';

export default function UserAvatarMenu() {
  const history = useHistory();

  const { setToken } = useContext(AuthContext) as AuthType;
  const { data, loading }: QueryType<UserQueryType, UserQueryVariablesType> =
    useQuery(UserQuery, {
      fetchPolicy: 'cache-first',
    });

  const user: Partial<UserType> = useMemo(
    function memorizeUser() {
      if (data?.me) {
        return data?.me;
      }

      return {};
    },
    [data]
  );

  const [logout, { loading: loadingLogout, called, client }] = useMutation(
    LogoutMutation,
    {
      onError: function handleLogoutError(error) {
        notification.open({
          message: 'An error has ocurred',
        });

        setToken(null);
        client.resetStore();
        history.push({ pathname: '/' });
      },
      onCompleted: function (data) {
        if (data.logout) {
          setToken(null);
          client.resetStore();
          history.push({ pathname: '/' });
        }
      },
    }
  );

  const handleLogout = useCallback(
    function handleLogoutCallback() {
      if (!called && !loadingLogout) {
        logout();
      }
    },
    [logout, called, loadingLogout]
  );

  const redirectToUserProfile = useCallback(
    function () {
      window.location.href = `https://osu.ppy.sh/users/${user.externalId}`;
    },
    [user.externalId]
  );

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu className={css``} mode="vertical" color="red">
          <Menu.ItemGroup title="User">
            <Menu.Item icon={<ProfileOutlined />} key="profile">
              <Link to="/app/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item
              onClick={redirectToUserProfile}
              icon={<LinkOutlined />}
              key="osuProfile"
            >
              Osu Profile
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.ItemGroup title="Settings">
            <Menu.Item icon={<SettingOutlined />} key="settings">
              <Link to="/app/settings">Settings</Link>
            </Menu.Item>
            <Menu.Item
              onClick={handleLogout}
              icon={<LogoutOutlined />}
              key="logout"
            >
              Logout
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      }
    >
      <Avatar
        className={css`
          box-sizing: content-box;
          border: 2px solid transparent;
          cursor: pointer;

          &:hover {
            border-color: blue;
            margin: 0px;
          }
        `}
        alt="User Avatar"
        shape="circle"
        src={user.avatar}
        size={48}
      />
    </Dropdown>
  );
}
