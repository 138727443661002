import styled from '@emotion/styled';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import AuthenticatedRouter from './AuthenticatedRouter';
import GuestRouter from './GuestRouter';
import AuthCallbackPage from '../pages/AuthCallback/AuthCallback';
import { Layout } from 'antd';
import { css } from '@emotion/css';

const Container = styled.div`
  margin: 0;
  padding: 0;
`;

export default function Router() {
  return (
    <Layout
      className={css`
        background: transparent;
        height: 100vh;
      `}
    >
      <BrowserRouter>
        <Route path="/auth/callback" exact strict>
          <AuthCallbackPage />
        </Route>
        <Route path="/" strict exact>
          <Redirect to="/home" from="/" />
        </Route>
        <GuestRouter />
        <AuthenticatedRouter />
      </BrowserRouter>
    </Layout>
  );
}
